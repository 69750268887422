import * as React from 'react';
import { Box, Button, Grid, Typography, InputLabel, OutlinedInput, MenuItem, FormControl, Select, TextField } from '@mui/material';
import { Phone, LinkedIn, Email, PhoneAndroid, Launch, Refresh, ExpandMore, ChevronLeft, Android, PhoneForwarded, Mail, ArrowRightAlt } from '@mui/icons-material';

export default function ParticipantPersonalDetails(props) {
    const { classes, jobs, personDetails, isOpen, setIsOpen, isNetworkDirector, handleInputChange, limitCalls, limitEmails, neverSms, neverContact, personChallenges, setPersonChallenges, saveInfo, setPersonFoodConsiderations1, setPersonFoodConsiderations2, setPersonFoodConsiderationsOther, personFoodConsiderations1, personFoodConsiderations2, personFoodConsiderationsOther, foodConsiderations, noAction } = props;

    return (
        <Box mb={2}>
            <Box className={classes.panel}>
                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                    <Typography variant="h1" component="h5" sx={{ fontSize: '18px', fontWeight: '500' }}>
                        Personal Details
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '10px' }}>
                            <PhoneAndroid sx={{ marginRight: '5px', fontSize: '14px' }} />  <a style={{ textDecoration: 'none', fontSize: '14px' }} href={`callto:${personDetails.person_mobile_number}`}>{personDetails.person_mobile_number}</a>
                        </Box>
                        {
                            jobs && jobs.main.length > 0 && jobs.main.filter((item) => item.job_is_primary)[0] && (
                                <>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}>
                                        {
                                            jobs.main.filter((item) => item.job_is_primary)[0].job_phone !== null && jobs.main.filter((item) => item.job_is_primary)[0].job_phone !== "" ? (
                                                <>
                                                    <PhoneForwarded sx={{ marginRight: '5px', fontSize: '14px' }} />  <a style={{ textDecoration: 'none', fontSize: '14px' }} href={`callto:${jobs.main.filter((item) => item.job_is_primary)[0].job_phone}`}>{jobs.main.filter((item) => item.job_is_primary)[0].job_phone}</a>
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Mail sx={{ marginRight: '5px', fontSize: '14px' }} /> <a style={{ textDecoration: 'none', fontSize: '14px' }} href={`mailto:${jobs.main.filter((item) => item.job_is_primary)[0].job_email}`}>{jobs.main.filter((item) => item.job_is_primary)[0].job_email}</a>
                                    </Box>
                                </>
                            )
                        }
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', minWidth: '100px' }}>
                        <ChevronLeft sx={{ cursor: 'pointer' }} className={isOpen ? "showUP" : "showDown"} onClick={() => setIsOpen(!isOpen)} />
                    </Box>
                </Box>
            </Box>
            {
                isOpen &&
                <Box className={classes.panel}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <table className={classes.personTable} style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Gender
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0, textTransform: 'capitalize' }}>
                                                        {personDetails.person_gender}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            {
                                                !isNetworkDirector && !noAction && (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                    Challenges
                                                                </Typography>
                                                            </td>
                                                            <td>
                                                                {/* {personDetails.person_challenges} */}
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <TextField
                                                                        type="text"
                                                                        id="person_challenges"
                                                                        label=""
                                                                        variant="outlined"
                                                                        size="small"
                                                                        style={{ width: '90%' }}
                                                                        value={personChallenges}
                                                                        onChange={(e) => {
                                                                            setPersonChallenges(e.target.value);
                                                                        }}
                                                                    ></TextField>
                                                                    <Button
                                                                        color="primary"
                                                                        variant="contained"
                                                                        sx={{ width: '9%', marginLeft: '1%' }}
                                                                        onClick={() => saveInfo('person_challenges')}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                    Food Considerations
                                                                </Typography>
                                                            </td>
                                                            <td>
                                                                <FormControl sx={{ minWidth: 120, width: '49%', marginRight: '1%' }} size="small">
                                                                    <InputLabel id="person_food_considerations_1">Type</InputLabel>
                                                                    <Select
                                                                        labelId="person_food_considerations_1"
                                                                        id="person_food_considerations_1"
                                                                        value={personFoodConsiderations1}
                                                                        label="Type"
                                                                        onChange={(e) => {
                                                                            setPersonFoodConsiderations1(e.target.value)
                                                                        }}
                                                                    >
                                                                        <MenuItem value="">-- Select Type--</MenuItem>
                                                                        {foodConsiderations && foodConsiderations.type1_food.map((item) => (
                                                                            <MenuItem
                                                                                key={item.id}
                                                                                value={item.id}
                                                                            >
                                                                                {item.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                <FormControl sx={{ minWidth: 120, width: '50%' }} size="small">
                                                                    <InputLabel id="person_food_considerations_2">Allergy</InputLabel>
                                                                    <Select
                                                                        labelId="person_food_considerations_2"
                                                                        id="person_food_considerations_2"
                                                                        multiple
                                                                        value={personFoodConsiderations2}
                                                                        onChange={(e) => {
                                                                            setPersonFoodConsiderations2(e.target.value);
                                                                            if (!e.target.value.includes(16)) {
                                                                                setPersonFoodConsiderationsOther("")
                                                                            }
                                                                        }}
                                                                        input={<OutlinedInput label="Name" />}
                                                                    >
                                                                        {foodConsiderations && foodConsiderations.type2_food.map((item) => (
                                                                            <MenuItem
                                                                                key={item.id}
                                                                                value={item.id}
                                                                            >
                                                                                {item.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                {
                                                                    personFoodConsiderations2.includes(16) && (
                                                                        <TextField
                                                                            type="text"
                                                                            id="food_consideration_other"
                                                                            name="food_consideration_other"
                                                                            label="Other"
                                                                            variant="outlined"
                                                                            size="large"
                                                                            style={{ marginTop: '10px', width: '100%' }}
                                                                            value={personFoodConsiderationsOther}
                                                                            onChange={(e) => {
                                                                                setPersonFoodConsiderationsOther(e.target.value);
                                                                            }}
                                                                        ></TextField>
                                                                    )
                                                                }
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    sx={{ display: 'block', width: '100%', marginTop: '10px' }}
                                                                    onClick={() => saveInfo('person_food_considerations')}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Special Conditions
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        {personDetails.person_special_conditions}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Mobile Number
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        <a href={`callto:${personDetails.person_mobile_number}`}>{personDetails.person_mobile_number}</a>
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Connections Count
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        {personDetails.person_li_connection_count}
                                                    </Typography>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Never Contact
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        <input
                                                            name="person_never_contact"
                                                            type="checkbox"
                                                            checked={neverContact}
                                                            onChange={handleInputChange}
                                                            className={classes.checkbox}
                                                            disabled={isNetworkDirector || noAction}
                                                        />
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Never SMS
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        <input
                                                            name="person_preferences_never_text"
                                                            type="checkbox"
                                                            checked={neverSms}
                                                            onChange={handleInputChange}
                                                            className={classes.checkbox}
                                                            disabled={isNetworkDirector || noAction}
                                                        />
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Limit Calls
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        <input
                                                            name="person_preferences_limit_calls"
                                                            type="checkbox"
                                                            checked={limitCalls}
                                                            onChange={handleInputChange}
                                                            className={classes.checkbox}
                                                            disabled={isNetworkDirector || noAction || noAction}
                                                        />
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Limit Emails
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        <input
                                                            name="person_preferences_limit_emails"
                                                            type="checkbox"
                                                            checked={limitEmails}
                                                            onChange={handleInputChange}
                                                            className={classes.checkbox}
                                                            disabled={isNetworkDirector || noAction}
                                                        />
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        CC Email
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        <a href={`mailto:${personDetails.person_cc_email}`}>{personDetails.person_cc_email}</a>
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        LinkedIn MemberID
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        {personDetails.person_linkedin_member_id}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Lead Source
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        {personDetails.lead_source}
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography variant="h1" component="h5" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                        Last Scrape Time
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography sx={{ fontSize: '14px', margin: 0 }}>
                                                        {personDetails.person_last_scrape_time}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    );
}
