import React, { useState } from "react";
import { List, ListItem } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const theme = createTheme({});
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        borderRadius: '0',
        boxShadow: '-6px 10px 12px #b6b6b6'
    },
}));

const MyMenu = (props) => {
    const { item, user } = props;
    const capabilities = user.user_details.capabilities.map((item) => item.capability_title)

    return (
        <ThemeProvider theme={theme}>
            <div className={item.items ? 'menu-item menu-item-has-children' : 'menu-item'} style={{ marginLeft: '8px', marginRight: '8px' }}>
                {
                    item.items && (item.capabilities === undefined || item.capabilities.some(r => capabilities.includes(r))) ?
                        <>
                            <Link
                                style={{ textDecoration: 'none', color: 'white' }}
                                to={{
                                    pathname: item.to
                                }
                                }
                            >
                                {item.title}
                            </Link>
                            <List className="sub-menu">
                                {
                                    item.items.filter((i) => i.capabilities === undefined || i.capabilities.some(r => capabilities.includes(r))).map((i, index) => <ListItem key={index}>
                                        <Link
                                            style={{ textDecoration: 'none', fontSize: '14px', color: "#000", padding: '5px 20px' }}
                                            to={{
                                                pathname: i.to
                                            }
                                            }
                                        >
                                            {i.title}
                                        </Link>
                                    </ListItem>)
                                }
                            </List>
                        </>
                        : (item.capabilities === undefined || item.capabilities.some(r => capabilities.includes(r))) && (
                            <Link
                                style={{ textDecoration: 'none', color: 'white' }}
                                to={{
                                    pathname: item.to
                                }
                                }
                            >
                                {item.title}
                            </Link>
                        )
                }
            </div>

        </ThemeProvider>
    );
};

export default MyMenu;
