import React from "react";
import {withStyles} from "@mui/styles";
import {Grid, CircularProgress} from "@mui/material";
import {selectors as dashboardSelectors, actions as dashboardActions} from '../../../Ducks/dashboard';
import {selectors as opportunitiesSelectors, actions as opportunitiesActions} from '../../../Ducks/opportunity';
import {selectors as accountSelectors} from '../../../Ducks/account';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import SalesRep from "./Widgets/SalesRep";
import Memberships from "./Widgets/MemberShips";
import Placement from "./Widgets/Placement";
import Calendar from "../../Containers/Calendar/Widgets/CalendarWidget/Calendar";

const useStyles = (_theme) => ({});

class MainScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modelOpen: false,
            selectedItem: null,
            propsData: props
        };
    }

    componentDidMount() {
        this.getDashboardData();
    }

    getDashboardData = () => {
        const {getDashboard, getPipelines, user, getTags} = this.props;
        getDashboard();
        getTags();
        this.getCalendarData();


        if (user && user.user_details.user_id) {
            getPipelines(user.user_details.user_id);
        }
    }

    getCalendarData = () => {
        const {getCalendar} = this.props;
        let d = new Date();
        let YearMonth = d.getFullYear(d) + '-' + ('0' + (d.getMonth() + 1)).slice(-2);
        getCalendar(YearMonth);
    }

    render() {
        const {
            dashboard,
            calendar,
            cronofyAuthorized,
            getCalendar_loading,
            getAllEvents_loading,
            events,
        } = this.props;
        return (
            <div className="dashboard">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid className="salesrep-widget" item xs={6}>
                                <SalesRep dashboard={dashboard}/>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    (getAllEvents_loading || (getCalendar_loading !== false)) ? <CircularProgress/> :
                                        (
                                            <Calendar {...this.props} events={cronofyAuthorized ? events : []}
                                                      legacy_events={calendar?.data.main}/>
                                        )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="membership-widget" item xs={6}>
                        <Memberships dashboard={dashboard}/>
                    </Grid>
                    <Grid className="placement-widget" item xs={6}>
                        <Placement dashboard={dashboard}/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: dashboardSelectors.isLoading(state),
    errorMessage: dashboardSelectors.errorMessage(state),
    dashboard: dashboardSelectors.dashboard(state),
    user: accountSelectors.user(state),
    calendar: opportunitiesSelectors.calendar(state),
    events: opportunitiesSelectors.events(state),
    getAllEvents_loading: opportunitiesSelectors.getAllEvents_loading(state),
    getCalendar_loading: opportunitiesSelectors.getCalendar_loading(state),
    getAllEvenets_inProgress: opportunitiesSelectors.getAllEvenets_inProgress(state),
    cronofyAuthorized: accountSelectors.cronofyAuthorized(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getDashboard: dashboardActions.getDashboard,
        getPipelines: dashboardActions.getPipelines,
        getCalendar: opportunitiesActions.getCalendar,
        getAllEvents: opportunitiesActions.getAllEvents,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(MainScreen));
