import {all, call, put, select, spawn, takeLatest} from "redux-saga/effects";

import {
	actions as opportunityActions,
	selectors as opportunitiesSelector,
	types as opportunityTypes
} from "../Ducks/opportunity";

import {callApi} from "./utils";
import * as api from "../API/opportunity";
import * as contactAPI from "../API/contacts";
import Swal from "sweetalert2";

function getCurrentPipelineOpportunities(opportunity) {
    const allOpportunities = JSON.parse(localStorage.getItem("sales_overview_opportunities")) || {};
    return allOpportunities[opportunity.data.main.pipeline_stage_id]?.opportunities || [];
}

function* preloadOpportunities(opportunity_ids, cache) {
    const opportunitiesToPreload = opportunity_ids.filter((id) => id && !cache[id]);
    yield all(
        opportunitiesToPreload.map((id) =>
            spawn(function* () {
                const opportunity = yield call(api.getOpportunityDetails, {id});
                yield put({type: opportunityTypes.cacheOpportunity, data: {[id]: opportunity}});
            })
        )
    );
}

function* getOpportunityDetails(action) {
    const {success, failure} = opportunityActions.saga.getOpportunityDetails;
    const {id, preloadNextPrevOpportunity} = action.data;
    const cache = yield select(opportunitiesSelector.opportunities_cache);

    let opportunity = cache[id];

    if (!preloadNextPrevOpportunity) {
        opportunity = null;
    }

    if (!opportunity) {
        opportunity = yield call(api.getOpportunityDetails, action.data);
        yield put({type: opportunityTypes.cacheOpportunity, data: {[id]: opportunity}});
    }

    if (preloadNextPrevOpportunity && opportunity) {
        const currentPipelineOpportunities = getCurrentPipelineOpportunities(opportunity);

        const currentOpportunityIndex = currentPipelineOpportunities.findIndex((oId) => oId == id);
        const nextOpportunityId = currentPipelineOpportunities[currentOpportunityIndex + 1];
        const prevOpportunityId = currentPipelineOpportunities[currentOpportunityIndex - 1];

        yield preloadOpportunities([nextOpportunityId, prevOpportunityId], cache);
    }

    yield callApi(opportunity, success, failure);
}

function* getEmployees(action) {
    const {success, failure} = opportunityActions.saga.getEmployees;

    yield callApi(call(api.getEmployees, action.data), success, failure);
}

function* getStages(action) {
    const {success, failure} = opportunityActions.saga.getStages;

    yield callApi(call(api.getStages, action.data), success, failure);
}

function* getUserPipelineStages(action) {
    const {success, failure} = opportunityActions.saga.getUserPipelineStages;

    yield callApi(call(api.getUserPipelineStages, action.data), success, failure);
}

function* getHistory(action) {
    const {success, failure} = opportunityActions.saga.getHistory;

    yield callApi(call(api.getHistory, action.data), success, failure);
}

function* getJobs(action) {
    const {success, failure} = opportunityActions.saga.getJobs;

    yield callApi(call(api.getJobs, action.data), success, failure);
}

function* getProducts(action) {
    const {success, failure} = opportunityActions.saga.getProducts;

    yield callApi(call(api.getProducts, action.data), success, failure);
}

function* getFilteredProducts(action) {
    const {success, failure} = opportunityActions.saga.getFilteredProducts;

    yield callApi(call(api.getFilteredProducts, action.data), success, failure);
}

function* getProductOverview(action) {
    const {success, failure} = opportunityActions.saga.getProductOverview;

    yield callApi(call(api.getProductOverview, action.data), success, failure);
}

function* getMails(action) {
    const {success, failure} = opportunityActions.saga.getMails;

    yield callApi(call(api.getMails, action.data), success, failure);
}

function* getSmsTemplates(action) {
    const {success, failure} = opportunityActions.saga.getSmsTemplates;

    yield callApi(call(api.getSmsTemplates, action.data), success, failure);
}

function* getOpportunities(action) {
    const {success, failure} = opportunityActions.saga.getOpportunities;

    yield callApi(call(api.getOpportunities, action.data), success, failure);
}

function* getLostReasons(action) {
    const {success, failure} = opportunityActions.saga.getLostReasons;

    yield callApi(call(api.getLostReasons, action.data), success, failure);
}

function* getSalesReps(action) {
    const {success, failure} = opportunityActions.saga.getSalesReps;

    yield callApi(call(api.getSalesReps, action.data), success, failure);
}

function* getPipelineId(action) {
    const {success, failure} = opportunityActions.saga.getPipelineId;

    yield callApi(call(api.getPipelineId, action.data), success, failure);
}

function* patchOpportunityData(action) {
    const {success, failure} = opportunityActions.saga.patchOpportunityData;

    function* postApiSuccessCallEffect() {
        yield put({
            type: opportunityTypes.removeOpportunityFromCache,
            data: {id: action.data.id},
        });
    }

    yield callApi(call(api.patchOpportunityData, action.data), success, failure, {postApiSuccessCallEffect});
}

function* searchCvr(action) {
    const {success, failure} = opportunityActions.saga.searchCvr;

    yield callApi(call(api.searchCvr, action.data), success, failure);
}

function* postOpportunityData(action) {
    const {success, failure} = opportunityActions.saga.postOpportunityData;

    function* postApiSuccessCallEffect() {
        yield put({
            type: opportunityTypes.removeOpportunityFromCache,
            data: {id: action.data.id},
        });
        yield call(getOpportunityDetails, action);
    }

    yield callApi(call(api.postOpportunityData, action.data), success, failure, {postApiSuccessCallEffect});
}

function* postNewJob(action) {
    const {success, failure} = opportunityActions.saga.postNewJob;
    const opportunityId = action.data.opportunityId;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: opportunityId},
    });

    function* postApiSuccessCallEffect() {
        Swal.fire({
            icon: "success",
            title: "Saved successfully!",
            showConfirmButton: false,
            timer: 1500,
        });
        yield call(getJobs, {...action, data: action.data.personId});
    }

    yield callApi(call(api.postNewJob, action.data), success, failure, {postApiSuccessCallEffect});
}

function* checkEvent(action) {
    const {success, failure} = opportunityActions.saga.checkEvent;
    const opportunityId = action.data.opportunityId;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: opportunityId},
    });

    yield callApi(call(api.checkEvent, action.data), success, failure, {});
}

function* unCheckEvent(action) {
    const {success, failure} = opportunityActions.saga.unCheckEvent;
    const opportunityId = action.data.opportunityId;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: opportunityId},
    });

    yield callApi(call(api.unCheckEvent, action.data), success, failure, {});
}

function* postClosedLostReasons(action) {
    const {success, failure} = opportunityActions.saga.postClosedLostReasons;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: action.data.id},
    });

    function* postApiSuccessCallEffect() {
        // Remove and set current opportunity from sales overview opportunity list
        const _sales_overview_opportunities = localStorage.getItem("sales_overview_opportunities")
            ? JSON.parse(localStorage.getItem("sales_overview_opportunities"))
            : undefined;
        if (_sales_overview_opportunities) {
            _sales_overview_opportunities[action.data.userStageId].opportunities = _sales_overview_opportunities[action.data.userStageId]
                .opportunities
                ? _sales_overview_opportunities[action.data.userStageId].opportunities.filter((item) => item !== parseInt(action.data.id))
                : [];
            localStorage.setItem("sales_overview_opportunities", JSON.stringify(_sales_overview_opportunities));
        }
        // Remove and set current opportunity from sales overview opportunity list
        yield call(getOpportunityDetails, {...action, data: {...action.data, id: action.data.id}});
    }

    yield callApi(call(api.postClosedLostReasons, action.data), success, failure, {postApiSuccessCallEffect});
}

function* postUnsuccessfulCampaign(action) {
    const {success, failure} = opportunityActions.saga.postUnsuccessfulCampaign;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: action.data.opportunityId},
    });

    function* postApiSuccessCallEffect() {
        // Remove and set current opportunity from sales overview opportunity list
        const _sales_overview_opportunities = localStorage.getItem("sales_overview_opportunities")
            ? JSON.parse(localStorage.getItem("sales_overview_opportunities"))
            : undefined;
        if (_sales_overview_opportunities) {
            _sales_overview_opportunities[action.data.userStageId].opportunities = _sales_overview_opportunities[action.data.userStageId]
                .opportunities
                ? _sales_overview_opportunities[action.data.userStageId].opportunities.filter((item) => item !== parseInt(action.data.id))
                : [];
            localStorage.setItem("sales_overview_opportunities", JSON.stringify(_sales_overview_opportunities));
        }

        yield call(getOpportunityDetails, {...action, data: {...action.data, id: action.data.id}});
    }

    yield callApi(call(api.postUnsuccessfulCampaign, action.data), success, failure, {postApiSuccessCallEffect});
}

function* postClosedWon(action) {
    const {success, failure} = opportunityActions.saga.postClosedWon;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: action.data.opportunityId},
    });

    function* postApiSuccessCallEffect() {
        // Remove and set current opportunity from sales overview opportunity list
        const _sales_overview_opportunities = localStorage.getItem("sales_overview_opportunities")
            ? JSON.parse(localStorage.getItem("sales_overview_opportunities"))
            : undefined;
        if (_sales_overview_opportunities) {
            _sales_overview_opportunities[action.data.userStageId].opportunities = _sales_overview_opportunities[action.data.userStageId]
                .opportunities
                ? _sales_overview_opportunities[action.data.userStageId].opportunities.filter((item) => item !== parseInt(action.data.id))
                : [];
            localStorage.setItem("sales_overview_opportunities", JSON.stringify(_sales_overview_opportunities));
        }
        // Remove and set current opportunity from sales overview opportunity list
        yield call(getOpportunityDetails, {...action, data: {...action.data, id: action.data.id}});
    }

    yield callApi(call(api.postClosedWon, action.data), success, failure, {postApiSuccessCallEffect});
}

function* switchProduct(action) {
    const {success, failure} = opportunityActions.saga.switchProduct;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: action.data.opportunityId},
    });

    function* postApiSuccessCallEffect() {
        yield call(getOpportunityDetails, {...action, data: {...action.data, id: action.data.opportunityId}});
    }

    yield callApi(call(api.switchProduct, action.data), success, failure, {postApiSuccessCallEffect});
}

function* applyEmailTemplate(action) {
    const {success, failure} = opportunityActions.saga.applyEmailTemplate;

    yield callApi(call(api.applyEmailTemplate, action.data), success, failure);
}

function* applySmsTemplate(action) {
    const {success, failure} = opportunityActions.saga.applySmsTemplate;

    yield callApi(call(api.applySmsTemplate, action.data), success, failure);
}

function* sendEmail(action) {
    const {success, failure} = opportunityActions.saga.sendEmail;

    function* postApiSuccessCallEffect() {
        Swal.fire({
            icon: "success",
            title: "Email Sent",
            showConfirmButton: false,
            timer: 1500,
        });
    }

    yield callApi(call(api.sendEmail, action.data), success, failure, {postApiSuccessCallEffect});
}

function* sendActionEmail(action) {
    const {success, failure} = opportunityActions.saga.sendActionEmail;

    function* postApiSuccessCallEffect() {
        Swal.fire({
            icon: "success",
            title: "Email Sent",
            showConfirmButton: false,
            timer: 1500,
        });
    }

    yield callApi(call(api.sendActionEmail, action.data), success, failure, {postApiSuccessCallEffect});
}

function* sendTestEmail(action) {
    const {success, failure} = opportunityActions.saga.sendTestEmail;

    function* postApiSuccessCallEffect() {
        Swal.fire({
            icon: "success",
            title: "Email Sent",
            showConfirmButton: false,
            timer: 1500,
        });
    }

    yield callApi(call(api.sendTestMail, action.data), success, failure, {postApiSuccessCallEffect});
}

function* sendSms(action) {
    const {success, failure} = opportunityActions.saga.sendSms;

    yield callApi(call(api.sendSms, action.data), success, failure);
}

function* addEventLog(action) {
    const {success, failure} = opportunityActions.saga.addEventLog;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: action.data.opportunityId},
    });

    function* postApiSuccessCallEffect() {
        yield call(getOpportunityDetails, {...action, data: {...action.data, id: action.data.opportunityId}});
        yield call(getHistory, {...action, data: {...action.data, id: action.data.opportunityId}});
    }

    yield callApi(call(api.addEventLog, action.data), success, failure, {postApiSuccessCallEffect});
}

function* addCalendarEvent(action) {
    const {success, failure} = opportunityActions.saga.addCalendarEvent;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: action.data.id},
    });

    function* postApiSuccessCallEffect() {
        yield call(getCalendar, {...action, data: action.data.date});
        yield call(getAllEvents, {...action});
    }

    yield callApi(call(api.addCalendarEvent, action.data), success, failure, {postApiSuccessCallEffect});
}

function* editCalendarEvent(action) {
    const {success, failure} = opportunityActions.saga.editCalendarEvent;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: action.data.opportunityId},
    });

    function* postApiSuccessCallEffect() {
        yield call(getHistory, {...action, data: {...action.data, id: action.data.opportunityId}});
        yield call(getOpportunityDetails, {...action, data: {...action.data, id: action.data.opportunityId}});
    }

    yield callApi(call(api.editCalendarEvent, action.data), success, failure, {postApiSuccessCallEffect});
}

function* updateExistingJob(action) {
    const {success, failure} = opportunityActions.saga.updateExistingJob;
    let opportunityId = '';
    let personId = '';
    let data = {};

    if (action?.data?.opportunityId) {
        opportunityId = action?.data?.opportunityId
    } else if (action.data?.data?.opportunityId) {
        opportunityId = action.data?.data?.opportunityId
    }

    if (action?.data?.personId) {
        personId = action?.data?.personId
    } else if (action.data?.data?.personId) {
        personId = action.data?.data?.personId
    }

    if (action?.data?.data) {
        data = action?.data?.data;
    } else if (action?.data) {
        data = action?.data
    }

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: opportunityId},
    });

    function* postApiSuccessCallEffect(res) {
        if (res.data.main.message === "success") {
            Swal.fire({
                icon: "success",
                title: "Saved successfully!",
                showConfirmButton: false,
                timer: 1500,
            });

            if (action?.data?.setShowJob) {
                action?.data?.setShowJob(false);
            }
        }
        yield call(getOpportunityDetails, {...action, data: {...action.data, id: opportunityId}});
        yield call(getJobs, {...action, data: personId});
    }

    yield callApi(call(api.updateExistingJob, {
        personId,
        jobs: data?.jobs
    }), success, failure, {postApiSuccessCallEffect});
}

function* updateStage(action) {
    const {success, failure} = opportunityActions.saga.updateStage;
    const opportunityId = action.data.opportunityId;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: opportunityId},
    });

    yield callApi(call(api.updateStage, action.data), success, failure);
}

function* updateDivisionPhone(action) {
    const {success, failure} = opportunityActions.saga.updateDivisionPhone;
    const opportunityId = action.data.opportunityId;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: [opportunityId]},
    });

    function* postApiSuccessCallEffect() {
        yield call(getOpportunityDetails, {...action, data: {...action.data, id: opportunityId}});
    }

    yield callApi(call(api.updateDivisionPhone, action.data), success, failure, {postApiSuccessCallEffect});
}

function* searchCompanies(action) {
    const {success, failure} = opportunityActions.saga.searchCompanies;

    yield callApi(call(api.searchCompanies, action.data), success, failure);
}

function* updateEvent(action) {
    const {success, failure} = opportunityActions.saga.updateEvent;
    yield callApi(call(api.updateEvent, action.data), success, failure);
}

function* getAllEvents(action) {
    const {success, failure} = opportunityActions.saga.getAllEvents;
    yield callApi(call(api.getAllEvents, action.data), success, failure);
}

function* getCalendar(action) {
    const {success, failure} = opportunityActions.saga.getCalendar;

    yield callApi(call(api.getCalendar, action.data), success, failure);
}

function* updateContact(action) {
    const {success, failure} = opportunityActions.saga.updateContact;
    const opportunityId = action.data.opportunityId;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: [opportunityId]},
    });

    function* postApiSuccessCallEffect() {
        if (!action.data.noSideEffect) {
            yield call(getOpportunityDetails, {...action, data: {...action.data, id: opportunityId}});
            yield call(getJobs, {...action, data: action.data.id});
        }
    }

    yield callApi(call(contactAPI.updateContact, action.data), success, failure, {postApiSuccessCallEffect});
}

function* updateJobPhone(action) {
    const {success, failure} = opportunityActions.saga.updateJobPhone;
    const opportunityId = action.data.opportunityId;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: [opportunityId]},
    });

    function* postApiSuccessCallEffect() {
        yield call(getOpportunityDetails, {...action, data: {...action.data, id: opportunityId}});
    }

    yield callApi(call(api.updateJobPhone, action.data), success, failure, {postApiSuccessCallEffect});
}

function* opportunityActiveCallAction(action) {
    const {success, failure} = opportunityActions.saga.opportunityActiveCallAction;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: action.data.opportunityId},
    });

    yield callApi(call(api.opportunityActiveCallAction, action.data), success, failure);
}

function* postOpportunityAction(action) {
    const {success, failure} = opportunityActions.saga.postOpportunityAction;
    const {
        data: {postData},
    } = action;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: postData.opportunityId},
    });

    function* postApiSuccessCallEffect(res) {
        if (action.data.handleTabChange) {
            action.data.handleTabChange('actions');
        }
    }

    yield callApi(call(api.opportunityActiveCallAction, postData), success, failure, {postApiSuccessCallEffect});
}

function* getPersons(action) {
    const {success, failure} = opportunityActions.saga.getPersons;

    yield callApi(call(api.getPersons, action.data), success, failure);
}

function* getUserPipelineContent(action) {
    const {success, failure} = opportunityActions.saga.getUserPipelineContent;

    yield callApi(call(api.getUserPipelineContent, action.data), success, failure);
}

function* getHistoryNoteTypes(action) {
    const {success, failure} = opportunityActions.saga.getHistoryNoteTypes;

    yield callApi(call(api.getHistoryNoteTypes, action.data), success, failure);
}

function* postPermissions(action) {
    const {success, failure} = opportunityActions.saga.postPermissions;
    const opportunityId = action.data.opportunityId;

    yield put({
        type: opportunityTypes.removeOpportunityFromCache,
        data: {id: [opportunityId]},
    });

    function* postApiSuccessCallEffect() {
        yield call(getOpportunityDetails, {...action, data: {...action.data, id: opportunityId}});
    }

    yield callApi(call(api.postPermissions, action.data), success, failure, {postApiSuccessCallEffect});
}

function* togglePin(action) {
    function* postApiSuccessCallEffect() {
        yield call(getHistory, {...action, data: {...action.data, id: action.data.oppId}});
    }

    const {success, failure} = opportunityActions.saga.togglePin;
    yield callApi(call(api.togglePin, action.data.logId), success, failure, {postApiSuccessCallEffect});
}

function* latestPlannedAction(action) {
    const {success, failure} = opportunityActions.saga.latestPlannedAction;
    yield callApi(call(api.latestPlannedAction, action.data), success, failure);
}

export default function* rootCustomerSaga() {
    yield takeLatest(opportunityTypes.getOpportunityDetails, getOpportunityDetails);
    yield takeLatest(opportunityTypes.getEmployees, getEmployees);
    yield takeLatest(opportunityTypes.getStages, getStages);
    yield takeLatest(opportunityTypes.getUserPipelineStages, getUserPipelineStages);
    yield takeLatest(opportunityTypes.getHistory, getHistory);
    yield takeLatest(opportunityTypes.getJobs, getJobs);
    yield takeLatest(opportunityTypes.getOpportunities, getOpportunities);
    yield takeLatest(opportunityTypes.getProducts, getProducts);
    yield takeLatest(opportunityTypes.getFilteredProducts, getFilteredProducts);
    yield takeLatest(opportunityTypes.getProductOverview, getProductOverview);
    yield takeLatest(opportunityTypes.getMails, getMails);
    yield takeLatest(opportunityTypes.getSmsTemplates, getSmsTemplates);
    yield takeLatest(opportunityTypes.getLostReasons, getLostReasons);
    yield takeLatest(opportunityTypes.getSalesReps, getSalesReps);
    yield takeLatest(opportunityTypes.getPipelineId, getPipelineId);
    yield takeLatest(opportunityTypes.patchOpportunityData, patchOpportunityData);
    yield takeLatest(opportunityTypes.postOpportunityData, postOpportunityData);
    yield takeLatest(opportunityTypes.postClosedLostReasons, postClosedLostReasons);
    yield takeLatest(opportunityTypes.postUnsuccessfulCampaign, postUnsuccessfulCampaign);
    yield takeLatest(opportunityTypes.postClosedWon, postClosedWon);
    yield takeLatest(opportunityTypes.searchCvr, searchCvr);
    yield takeLatest(opportunityTypes.checkEvent, checkEvent);
    yield takeLatest(opportunityTypes.unCheckEvent, unCheckEvent);
    yield takeLatest(opportunityTypes.switchProduct, switchProduct);
    yield takeLatest(opportunityTypes.applyEmailTemplate, applyEmailTemplate);
    yield takeLatest(opportunityTypes.applySmsTemplate, applySmsTemplate);
    yield takeLatest(opportunityTypes.sendEmail, sendEmail);
    yield takeLatest(opportunityTypes.sendActionEmail, sendActionEmail);
    yield takeLatest(opportunityTypes.sendSms, sendSms);
    yield takeLatest(opportunityTypes.postNewJob, postNewJob);
    yield takeLatest(opportunityTypes.addEventLog, addEventLog);
    yield takeLatest(opportunityTypes.addCalendarEvent, addCalendarEvent);
    yield takeLatest(opportunityTypes.editCalendarEvent, editCalendarEvent);
    yield takeLatest(opportunityTypes.updateExistingJob, updateExistingJob);
    yield takeLatest(opportunityTypes.updateStage, updateStage);
    yield takeLatest(opportunityTypes.searchCompanies, searchCompanies);
    yield takeLatest(opportunityTypes.getCalendar, getCalendar);
    yield takeLatest(opportunityTypes.getAllEvents, getAllEvents);
    yield takeLatest(opportunityTypes.updateEvent, updateEvent);
    yield takeLatest(opportunityTypes.updateDivisionPhone, updateDivisionPhone);
    yield takeLatest(opportunityTypes.updateContact, updateContact);
    yield takeLatest(opportunityTypes.updateJobPhone, updateJobPhone);
    yield takeLatest(opportunityTypes.opportunityActiveCallAction, opportunityActiveCallAction);
    yield takeLatest(opportunityTypes.postOpportunityAction, postOpportunityAction);
    yield takeLatest(opportunityTypes.getPersons, getPersons);
    yield takeLatest(opportunityTypes.getUserPipelineContent, getUserPipelineContent);
    yield takeLatest(opportunityTypes.getHistoryNoteTypes, getHistoryNoteTypes);
    yield takeLatest(opportunityTypes.postPermissions, postPermissions);
    yield takeLatest(opportunityTypes.sendTestEmail, sendTestEmail);
    yield takeLatest(opportunityTypes.togglePin, togglePin);
    yield takeLatest(opportunityTypes.latestPlannedAction, latestPlannedAction);
}
