import * as React from "react";
import { Paper, FormControlLabel, Checkbox, Button } from "@mui/material";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
	Scheduler,
	DayView,
	WeekView,
	Appointments,
	Toolbar,
	DateNavigator,
	TodayButton,
	MonthView,
	Resources,
	AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import AddEventModal from "./AddEventModal";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { styled } from "@mui/material/styles";

const showProgress = () => (
	<div style={{ display: "flex", justifyContent: "center" }}>
		<CircularProgress />
	</div>
);

const PREFIX = "ActionCalendar";

const classes = {
	dayScaleCell: `${PREFIX}-dayScaleCell`,
};

const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)({
	[`&.${classes.dayScaleCell}`]: {
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
});

const formatDayScaleDate = (date, options) => {
	const momentDate = moment(date);
	const { weekday } = options;
	return momentDate.format(weekday ? "ddd" : "D");
};
const formatTimeScaleDate = (date) => moment(date).format("HH:mm");

const DayScaleCell = ({ formatDate, ...restProps }) => (
	<StyledWeekViewDayScaleCell {...restProps} formatDate={formatDayScaleDate} className={classes.dayScaleCell} />
);
const TimeScaleLabel = (
    {formatDate, ...restProps},
) => <WeekView.TimeScaleLabel {...restProps} formatDate={formatTimeScaleDate}/>;


class ActionCalendar extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: props.calendar ? this.appointmentsData(props.calendar.data.main) : [],
			meetings: true,
			phones: true,
			currentViewName: "Week",
			openEventModal: false,
			modalData: {},
		};
	}

	componentDidUpdate(prevProps, _prevState) {
		if (prevProps.calendar !== this.props.calendar) {
			this.setState({
				data: this.appointmentsData(this.props.calendar.data.main),
			});
		}
	}

	currentViewNameChange = (e) => {
		this.setState({ currentViewName: e });
	};

	handleFilter = ({ meetings, phones }) => {
		const list = this.appointmentsData(this.props.calendar.data.main);
		const filteredList = list.filter((item) => {
			if (meetings && item.location === "Meetings") {
				return true;
			}
			if (phones && item.location === "Calls") {
				return true;
			}
			return false;
		});
		this.setState({ data: filteredList });
	};

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.checked }, () => this.handleFilter(this.state));
	};

	appointmentsData = (apiResData) => {
		const erpDataArray = [];
		if (!apiResData) {
			return erpDataArray;
		}
		let resData = apiResData.length ? apiResData : [apiResData];
		resData.map((item, index) => {
			var tempObj = {};
			tempObj["id"] = index;
			let sDate = item.opportunity_action_due_time;
			tempObj["startDate"] = new Date(
				sDate.slice(0, 4),
				parseInt(sDate.slice(5, 7) - 1),
				sDate.slice(8, 10),
				sDate.slice(11, 13),
				sDate.slice(14, 16),
				sDate.slice(17, 19)
			);
			tempObj["endDate"] = new Date(
				sDate.slice(0, 4),
				parseInt(sDate.slice(5, 7) - 1),
				sDate.slice(8, 10),
				sDate.slice(11, 13),
				sDate.slice(14, 16),
				sDate.slice(17, 19)
			);
			tempObj["title"] = item.person_name;
			tempObj["opportunity_id"] = item.opportunity_id;
			if (item.opportunity_action_type === "phone_meeting") {
				tempObj["location"] = "Meetings";
			}
			if (item.opportunity_action_type === "follow_up") {
				tempObj["location"] = "Calls";
			}
			let eDate = new Date(tempObj["startDate"]);
			tempObj["endDate"].setMinutes(eDate.getMinutes() + 15);
			erpDataArray.push(tempObj);
		});
		return erpDataArray;
	};

	handleCellClick = (data) => {
		this.setState({ openEventModal: !this.state.openEventModal, modalData: data });
	};

	WeekTimeTableCell = (props) => (
		<WeekView.TimeTableCell
			{...props}
			onDoubleClick={() => this.props.isClickable && this.handleCellClick(props)}
			onClick={() => this.props.isClickable && this.handleCellClick(props)}
		/>
	);

	MonthTimeTableCell = (props) => (
		<MonthView.TimeTableCell
			{...props}
			onDoubleClick={() => this.props.isClickable && this.handleCellClick(props)}
			onClick={() => this.props.isClickable && this.handleCellClick(props)}
		/>
	);

	DayTimeTableCell = (props) => (
		<DayView.TimeTableCell
			{...props}
			onDoubleClick={() => this.props.isClickable && this.handleCellClick(props)}
			onClick={() => this.props.isClickable && this.handleCellClick(props)}
		/>
	);

	appointmentTooltipHandle = (data) => {
		const { navigate } = this.props;
		navigate(`/opportunities/id/${data.data.opportunity_id}?tab=history`);
	};

	render() {
		const { data, currentViewName } = this.state;
		const { opportunity } = this.props;

		const LOCATIONS = ["Meetings", "Calls"];
		const resources = [
			{
				fieldName: "location",
				title: "Location",
				instances: [
					{ id: LOCATIONS[0], text: LOCATIONS[0], color: "#0000ff" },
					{ id: LOCATIONS[1], text: LOCATIONS[1], color: "#0000008a" },
				],
			},
		];

		if (this.props.isLoading) {
			return showProgress();
		}

		return (
			<Paper className={currentViewName.toLowerCase()}>
				<Scheduler data={data} height={660}>
					<ViewState defaultCurrentDate={formatDate(new Date())} currentViewName={currentViewName} />

					<DayView
						startDayHour={8}
						endDayHour={20}
						timeTableCellComponent={this.DayTimeTableCell}
						dayScaleCellComponent={DayScaleCell}
						timeScaleLabelComponent={TimeScaleLabel}
					/>
					<WeekView
						name='Week'
						displayName='Week'
						excludedDays={[0, 6]}
						startDayHour={8}
						endDayHour={20}
						timeTableCellComponent={this.WeekTimeTableCell}
						dayScaleCellComponent={DayScaleCell}
						timeScaleLabelComponent={TimeScaleLabel}
					/>
					<MonthView timeTableCellComponent={this.MonthTimeTableCell} startDayHour={8} endDayHour={20} />

					<Toolbar />
					<DateNavigator />
					<TodayButton />

					<Appointments />

					<AppointmentTooltip showCloseButton showOpenButton visible={false} onAppointmentMetaChange={this.appointmentTooltipHandle} />

					<Resources data={resources} />
					<div style={{ display: "flex", justifyContent: "space-between", paddingTop: "12px" }}>
						<div style={{ marginLeft: "33px" }}>
							<FormControlLabel
								label='Phone meetings'
								control={
									<Checkbox
										checked={this.state.meetings}
										onChange={this.handleChange}
										name={"meetings"}
										inputProps={{ "aria-label": "controlled" }}
									/>
								}
							/>
							<FormControlLabel
								label='Calls'
								control={
									<Checkbox
										checked={this.state.phones}
										onChange={this.handleChange}
										name={"phones"}
										inputProps={{ "aria-label": "controlled" }}
									/>
								}
							/>
						</div>
						<ExternalViewSwitcher currentViewName={currentViewName} onChange={this.currentViewNameChange} />
					</div>
				</Scheduler>
				{this.state.openEventModal && (
					<AddEventModal
						open={this.state.openEventModal}
						tab={this.props.tab}
						handleClose={() => this.setState({ openEventModal: !this.state.openEventModal })}
						startDate={this.state.modalData.startDate}
						opportunity={opportunity}
						isUpcomingEvent={this.state.data.filter((x) => x.opportunity_id === opportunity.main.opportunity_id).length > 0}
					/>
				)}
			</Paper>
		);
	}
}

function WithNavigate(props) {
	let navigate = useNavigate();
	return <ActionCalendar {...props} navigate={navigate} />;
}

export default WithNavigate;

const ExternalViewSwitcher = ({ currentViewName, onChange }) => (
	<div style={{ width: "400px" }}>
		<Button
			style={{ marginRight: "12px" }}
			onClick={() => onChange("Day")}
			variant={currentViewName === "Day" ? "contained" : "text"}
			name={"Day"}
		>
			Day
		</Button>
		<Button onClick={() => onChange("Week")} variant={currentViewName === "Week" ? "contained" : "text"} name={"Week"}>
			Week
		</Button>
		<Button
			style={{ marginLeft: "12px" }}
			onClick={() => onChange("Month")}
			variant={currentViewName === "Month" ? "contained" : "text"}
			name={"Month"}
		>
			Month
		</Button>
	</div>
);

function formatDate(date) {
	return moment(date).format("YYYY-MM-DD");
}
