import {format} from 'date-fns';

export var isDate = function (date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export function formatDate(str) {
    const d = new Date(str);
    return (('0' + d.getDate()).slice(-2) + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear())
}

export function toDateOnly(str) {
    const dateObj = new Date(str);
    return (dateObj.getUTCDate()) + "/" + (dateObj.getMonth() + 1) + "/" + (dateObj.getUTCFullYear())
}

export function toDateTime(str) {
    const dateObj = new Date(str);
    return (dateObj.getUTCDate()) + "/" + (dateObj.getMonth() + 1) + "/" + (dateObj.getUTCFullYear()) + " " + dateObj.getHours() + ":" + (dateObj.getMinutes() < 10 ? '0' + dateObj.getMinutes() : dateObj.getMinutes())
}

export function tryParseDate(str) {
    if (!isInt(str) && isDate(str)) {
        return toDateOnly(str);
    }

    return str;
}

function isInt(value) {
    return !isNaN(value) && (function (x) {
        return (x | 0) === x;
    })(parseFloat(value))
}

export function convertToDecimalNumber(number) {
    return number?.toFixed(2);
}

export function addDays(dateToAddDays, noOfDays) {
    dateToAddDays.setDate(dateToAddDays.getDate() + noOfDays);

    return dateToAddDays;
}

export function subtractDays(dateToAddDays, noOfDays) {
    dateToAddDays.setDate(dateToAddDays.getDate() - noOfDays);
    return dateToAddDays;
}

export function dateToString(date, formatString = 'dd/MM/yyyy') {
    let formattedDate = '';

    if (date && isDate(date)) {
        formattedDate = format(date, formatString);
    }

    return formattedDate;
}