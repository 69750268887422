import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Card, CardContent, CardMedia, Button, Grid, Typography, InputLabel, OutlinedInput, MenuItem, FormControl, Select, TextField, Radio } from '@mui/material';
import { Phone, Language, BusinessCenter, LinkedIn, Email, PhoneAndroid, Launch, Refresh, ExpandMore, ChevronLeft, Android, PhoneForwarded, Mail, ArrowRightAlt } from '@mui/icons-material';
import moment from 'moment';

export default function ParticipantPurchases(props) {
    const { classes, personPurchases } = props;

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className={classes.accordionSummary}
            >
                <Typography variant="h1" component="h5" sx={{ fontSize: '18px', fontWeight: '500', marginBottom: '10px' }}>
                    Purchases
                </Typography>
                {personPurchases && personPurchases.filter((item) => item.purchase_actual_status !== 'Former' && item.purchase_actual_status !== 'No events').map((purchase, index) => {
                    return (
                        <Grid key={index} container spacing={2} sx={{ padding: '10px 0', marginTop: 0 }}>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>
                                <Typography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                    <span>
                                        {purchase.purchase_id}
                                    </span>
                                    <span className={`tag ${purchase.purchase_actual_status}`}>
                                        {purchase.purchase_actual_status}
                                    </span>
                                </Typography>
                                {
                                    purchase.opportunity_id && (
                                        <Typography style={{ fontSize: '12px' }} gutterBottom variant="h6" component="div">
                                            Opp ID ({purchase.opportunity_id})
                                        </Typography>
                                    )
                                }

                            </Grid>
                            <Grid item xs={6} sx={{ paddingTop: '0 !important' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                        {purchase.product_name} - {purchase.product_type_name}
                                    </Typography>
                                    <Typography
                                        style={{ flexGrow: "1", fontSize: '11px' }}
                                        gutterBottom
                                        variant="span"
                                        component="span"
                                    >
                                        {moment(purchase.purchase_start).format("DD/MM/YYYY")} -{" "}{moment(purchase.purchase_stop).format("DD/MM/YYYY")}

                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>
                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {purchase.purchase_price}{purchase.purchase_price_currency}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>
                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {purchase.purchase_description}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </AccordionSummary>
            <AccordionDetails>
                {personPurchases && personPurchases.filter((item) => item.purchase_actual_status !== 'Current' && item.purchase_actual_status !== 'Future').map((purchase, index) => {
                    return (
                        <Grid key={index} container spacing={2} sx={{ padding: '10px 0', borderTop: '1px solid #c2c2c2', marginTop: 0 }}>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>
                                <Typography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                    <span>
                                        {purchase.purchase_id}
                                    </span>
                                    <span className={`tag ${purchase.purchase_actual_status}`}>
                                        {purchase.purchase_actual_status}
                                    </span>
                                </Typography>
                                {
                                    purchase.opportunity_id && (
                                        <Typography style={{ fontSize: '12px' }} gutterBottom variant="h6" component="div">
                                            Opp ID ({purchase.opportunity_id})
                                        </Typography>
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} sx={{ paddingTop: '0 !important' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '13px' }} gutterBottom variant="h6" component="div">
                                        {purchase.product_name} - {purchase.product_type_name}
                                    </Typography>
                                    <Typography
                                        style={{ flexGrow: "1", fontSize: '11px' }}
                                        gutterBottom
                                        variant="span"
                                        component="span"
                                    >
                                        {moment(purchase.purchase_start).format("DD/MM/YYYY")} -{" "}{moment(purchase.purchase_stop).format("DD/MM/YYYY")}

                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>

                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {purchase.purchase_price}{purchase.purchase_price_currency}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingTop: '0 !important' }}>
                                <Typography
                                    style={{ padding: "0 10px 0 0", fontSize: '13px' }}
                                    gutterBottom
                                    className={classes.typography}
                                    variant="span"
                                    component="span"
                                >
                                    {purchase.purchase_description}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </AccordionDetails>
        </Accordion>
    );
}