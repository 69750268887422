import React from "react";
import {selectors as contactsSelectors, actions as contactsActions} from "../../../Ducks/contacts";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {fetchGet} from "../../../API/util";
import GridElement from "../../Common/GidElement";
import {Button} from "@mui/material";
import EditTable from "../../Common/EditTable";
import {cloneDeep, isEmpty} from "lodash";

const makeHeader = (title) => {
    let _title = "";
    if (title.includes(".")) {
        _title = title.split(".")[1].split("_");
    } else {
        _title = title.split("_");
    }
    return _title.join(" ");
};

const makeAccessor = (key) => {
    if (key.includes(".")) {
        return key.split(".")[1];
    } else {
        return key;
    }
};

const ContactsGrid = (props) => {
    const {navigate} = props;
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
    const [isOpen, setIsOpen] = React.useState(false);
    const [cols, setCols] = React.useState(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const dynamicColumns = user.tableSettings.persons.columns.map(key => ({
            column: key,
            accessor: makeAccessor(key),
            minWidth: 150,
            Header: makeHeader(key),
            filter: 'fuzzyText'
        }));
        return [...dynamicColumns];
    });
    const fetchIdRef = React.useRef(0);

    const fetchData = React.useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {
        // This will get called when the table needs new data
        // You could fetch your data from literally anywhere,
        // even a server. But for this example, we'll just fake it.

        // Parse columns
        const _columns = columns.filter(item => item.column != 'persons.tags').map((item) => item.column)

        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        // Set the loading state
        setLoading(true);

        // Check filter data
        let query = "",
            api = "",
            sort = "",
            filterPrefix = "";

        filters.forEach((item, _index) => {
            const _filterColumn = columns.filter((column) => column.accessor === item.id);

            if (_filterColumn[0].column.includes(".")) {
                filterPrefix = _filterColumn[0].column.split(".")[0];
            } else {
                filterPrefix = "persons";
            }

            query = query + `&contains[${filterPrefix}.${item.id}]=${item.value}`;
        });

        // Check sorting
        if (sortBy.length) {
            let _sortByPrefix = "",
                _sortByColumn = columns.filter((column) => column.accessor === sortBy[0].id);
            if (_sortByColumn[0].column.includes(".")) {
                _sortByPrefix = _sortByColumn[0].column.split(".")[0];
                _sortByPrefix = `${_sortByPrefix}.`;
            }

            sort = `&sortBy=${sortBy[0].desc ? "-" : ""}${_sortByPrefix}${sortBy[0].id}`;
        }

        api = `/persons?results=${pageSize}&columns=${_columns}&offset=${pageIndex + 1}${query}${sort}`;

        fetchGet(api)
            .then((contacts) => {
                if (fetchId === fetchIdRef.current && contacts) {
                    const _contacts = cloneDeep(contacts);
                    const regions = contacts.data.related_objects?.["regions"];

                    if (!isEmpty(contacts.data.related_objects)) {
                        _contacts.data.main.forEach((contact, _index) => {
                            if (regions && !isEmpty(regions[contact.region_id])) {
                                const _region_obj = cloneDeep(regions[contact.region_id]);

                                delete _region_obj["region_id"];
								
                                Object.assign(contact, _region_obj);
                            }
                        });
                    }
                    setData(_contacts.data.main);
                    setPageCount(contacts.data.offset.highest_offset);
                    setRowsCount(contacts.data.offset.rows_count);
                    setLoading(false);
                }
            })
            .catch((_error) => {
                setLoading(false);
            });
    }, []);

    const resetFilters = React.useCallback(() => setFilters([]), [setFilters]);

    const handleRowClick = (row) => {
        navigate("/contact/id/" + row.original.person_id);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <h2 className='page-title'>Contacts</h2>
                <Button variant='outlined' color='primary' onClick={() => setIsOpen(true)}>
                    Edit Table
                </Button>
            </div>

            <GridElement
                columns={cols}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                rowsCount={rowsCount}
                handleRowClick={handleRowClick}
                initialSortBy='person_firstname'
            />

            <EditTable open={isOpen} onClose={handleClose} columns={["persons", "regions"]} table='persons'
                       setColumns={setCols}/>
        </>
    );
};

const mapStateToProps = (state) => ({
    contacts: contactsSelectors.contacts(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContacts: contactsActions.getContacts,
        },
        dispatch
    );

function WithNavigate(props) {
    let navigate = useNavigate();
    return <ContactsGrid {...props} navigate={navigate}/>;
}

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate);
