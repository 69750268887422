import {fetchGet, fetchPatch} from "./util";

export const getContacts = ({results, columns, offset, filter = null}) => {
    if (filter) {
        return fetchGet(`/persons?results=${results}&columns=${columns}&sortBy=person_firstname&offset=${offset}&contains[persons.${filter.column}]=${filter.value}`).then((res) => res);
    } else {
        return fetchGet(`/persons?results=${results}&columns=${columns}&sortBy=person_firstname&offset=${offset}`).then((res) => res);
    }
};

export const getContactDetails = ({id, columns}) => {
    return fetchGet(`/persons/${id}?columns=${columns}`).then((res) => res);
};

export const getJobs = ({id, columns}) => {
    return fetchGet(
        `/jobs?columns=${columns},persons.person_id&equals[persons.person_id]=${id}`
    ).then((res) => res);
};

export const getOpportunitiesList = ({id, columns}) => {
    return fetchGet(
        `/opportunities?columns=${columns},jobs.job_id,persons.person_firstname&sortBy=opportunity_id&&equals[persons.person_id]=${id}`
    ).then((res) => res);
};

export const getSalesList = ({id, columns}) => {
    return fetchGet(
        `/purchases?columns=${columns},jobs.job_id,persons.person_firstname&equals[persons.person_id]=${id}`
    ).then((res) => res);
};

export const getPlannedCalls = ({id, columns}) => {
    return fetchGet(
        `/planned_calls?columns=${columns},persons.person_id&equals[persons.person_id]=${id}`
    ).then((res) => res);
};

export const getFoodConsiderations = () => {
    return fetchGet(`/food-consideration-options`).then((res) => res);
};

export const getPersonMiniProfile = (person_id) => {
    return fetchGet(`/persons/${person_id}/mini-profile`).then((res) => res);
};

export const updateContact = (data) => {
    return fetchPatch(`/persons/${data.id}`, data.obj).then((res) => res);
};

export const getTags = () => {
  return fetchGet(`/tags`).then((res) => res);
}
