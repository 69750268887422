import React from "react";
import {
    BrowserRouter as Router,
    Routes as RouterRoutes,
    Route,
} from "react-router-dom";
import SignIn from '../Components/Containers/SignIn';
import ForgotPassword from '../Components/Containers/ForgotPassword';
import {selectors as accountSelectors, actions as accountActions} from '../Ducks/account';
import {connect} from 'react-redux';
import Layout from '../Components/Common/Layout';
import {adminRoutes, networkDirectorRoutes, salesRepRoutes} from "./userRoutes.js";
import NotFound from "../Components/Containers/404";
import {useMsal} from "@azure/msal-react";
import {useIsAuthenticated} from "@azure/msal-react";
import {loginRequest} from "../Azure/authConfig";
import {bindActionCreators} from "redux";
import BlankPage from "../Components/Containers/BlankPage";
import {user_logout} from "../API/util";
import CreateResetPassword from "../Components/Containers/CreateResetPassword.js";

const Routes = (props) => {
    const {user, loggedIn} = props;
    const role = user && user.user_details.capabilities;
    const capabilities = user && user.user_details.capabilities.map((item) => item.capability_title)
    const isAuthenticated = useIsAuthenticated();
    const {instance, accounts} = useMsal();
    const [accessToken, setAccessToken] = React.useState(null);

    React.useEffect(() => {
        // check for auto logout
        if (user_logout.timer) {
            window.clearTimeout(user_logout.timer);
            user_logout.timer = null;
        }
        const now = Date.now();
        const user_login_timestamp = localStorage.getItem('user_login_timestamp');

        if (user_login_timestamp) {
            const difference = now - user_login_timestamp;
            const timeDifference = (((difference / 1000) / 60) / 60);
            const remaining = user_logout.time - timeDifference;
            const count = remaining * 60 * 60 * 1000;
            if (timeDifference > user_logout.time) {
                props.logout();
            } else {
                user_logout.timer = setTimeout(() => {
                    props.logout();
                }, count);
            }
        }

    }, [])

    React.useEffect(() => {
        if (isAuthenticated) {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(request).then((response) => {
                setAccessToken(response.accessToken);
            }).catch(() => {
                instance.acquireTokenPopup(request).then((response) => {
                    setAccessToken(response.accessToken);
                });
            });
        }
    }, [isAuthenticated])

    React.useEffect(() => {
        if (accessToken && !loggedIn) {
            props.azureLogin({access_token: accessToken});
        }
    }, [accessToken])

    if (!loggedIn || (!loggedIn && isAuthenticated && accessToken)) {
        return (
            <Router>
                <RouterRoutes>
                    <Route exact path="/" element={<SignIn/>}/>
                    <Route exact path="/reset-password" element={<ForgotPassword/>}/>
                    <Route path="*" element={<SignIn/>}/>
                    <Route path="/reset-password/token/:token" element={<CreateResetPassword/>}/>
                </RouterRoutes>
            </Router>
        );
    }

    if (role && role.filter(item => item.capability_title === "admin").length > 0) {
        return (
            <div>
                <Router>
                    <Layout>
                        <RouterRoutes>
                            {
                                adminRoutes.filter((i) => i.capabilities === undefined || i.capabilities.some(r => capabilities.includes(r))).map((route, index) => (
                                    <Route key={index} exact path={route.path} element={route.component}/>
                                ))
                            }
                            <Route path="*" element={<NotFound/>}/>
                        </RouterRoutes>
                    </Layout>
                </Router>
            </div>

        );
    } else if (role && role.filter(item => item.capability_title === "salesrep").length > 0) {
        return (
            <div>
                <Router>
                    <Layout>
                        <RouterRoutes>
                            {
                                salesRepRoutes.filter((i) => i.capabilities === undefined || i.capabilities.some(r => capabilities.includes(r))).map((route, index) => (
                                    <Route key={index} exact path={route.path} element={route.component}/>
                                ))
                            }
                            <Route path="*" element={<NotFound/>}/>
                        </RouterRoutes>
                    </Layout>
                </Router>
            </div>

        );
    } else if (role && role.filter(item => item.capability_title === "is_network_director").length > 0) {
        return (
            <div>
                <Router>
                    <Layout>
                        <RouterRoutes>
                            {
                                networkDirectorRoutes.filter((i) => i.capabilities === undefined || i.capabilities.some(r => capabilities.includes(r))).map((route, index) => (
                                    <Route key={index} exact path={route.path} element={route.component}/>
                                ))
                            }
                            <Route path="*" element={<NotFound/>}/>
                        </RouterRoutes>
                    </Layout>
                </Router>
            </div>

        );
    } else {
        return (
            <div>
                <Router>
                    <Layout>
                        <RouterRoutes>
                            <Route path="*" element={<BlankPage/>}/>
                        </RouterRoutes>
                    </Layout>
                </Router>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedIn: accountSelectors.isLoggedIn(state),
    user: accountSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        login: accountActions.login,
        logout: accountActions.logout,
        azureLogin: accountActions.azureLogin
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
