import {fetchDelete, fetchGet} from "./util";

export const deleteInvoice = (invoice_id) => {
    return fetchDelete(`/invoices/${invoice_id}`).then(res => res);
}
export const getLayouts = () => {
    return fetchGet(`/invoice-layouts`).then(res => res);
}
export const getProducts = () => {
    return fetchGet(`/invoice-products`).then(res => res);
}
export const getPaymentTerms = () => {
    return fetchGet(`/invoice-payment-terms`).then(res => res);
}